// Here you can add other styles
//.c-sidebar .c-sidebar-nav > .c-sidebar-nav-dropdown:active
//{
//  background: black!important;
//  color: #f9a100 !important;
//}
//.c-sidebar .c-sidebar-nav > .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items
//{
//  background: #5381e2 !important;
//}

.no-border-table-wrapper {
  table,
  tr,
  td,
  th {
    border: none;
  }
}

body {
  > iframe:last-of-type {
    display: none !important;
  }
}

label.required {
  &::after {
    content: ' *';
    color: red;
  }
}

.c-sidebar {
  //background: #2982cc    !important;
  //background: #black    !important;
  font-weight: 700 !important;
  color: yellow !important;
  font-size: 16px !important;
  //background: black     !important;

  // * {
  //   background: #2982cc !important;
  // }
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir='rtl'])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle {
  padding-left: 5% !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle {
  // background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 8px;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
  padding-left: 0px !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 3% !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

//
//tr:nth-child(even) {
//  background-color: #dddddd;
//}

.marginLeftButtonCross {
  margin-left: 42rem !important;
}

// @media (min-width: 992px) {
//   html:not([dir='rtl'])
//     .c-sidebar.c-sidebar-lg-show.c-sidebar-minimized:not(.c-sidebar-right),
//   html:not([dir='rtl'])
//     .c-sidebar.c-sidebar-show.c-sidebar-minimized:not(.c-sidebar-right) {
//     width: 18% !important;
//   }
// }

.ml-9 {
  margin-left: 90% !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  display: flex !important;
  justify-content: center !important;
  margin-left: 10px !important;
  align-items: center !important;
  color: transparent !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.font-20 {
  font-size: 20px !important;
}

/********************************************************/

.mlw-wrapper {
  padding: 16px;
  text-align: center;

  .mlw-title {
    margin-bottom: 16px;
  }

  .mlw-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .mlw-link {
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

.auto-count {
  counter-increment: css-counter 1;

  &-wrapper {
    list-style-type: none;
    counter-reset: css-counter 0;
  }

  &::before {
    content: counter(css-counter) ') ';
    font-weight: 500;
  }
}

.form-control {
  color: #444 !important;
}
